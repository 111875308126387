@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

/* variable setup */
$main-bg-colour: rgba(245, 245, 245, 1);
$main-font-colour: rgba(0, 122, 255, 1);
$secondary-bg-colour: hsl(23, 100%, 50%);
$light-orange: rgba(236, 127, 55, 1);
$burnt-orange: rgba(190, 79, 12, 1);
$danger: crimson;
$link-colour: lightblue;
$success: rgba(92, 184, 92, 1);
$warning: rgba(240, 173, 78, 1);
$rating-colour: orange;
$border-colour: rgba(200, 200, 200, 0.5);
$border-colour-dark: hsla(0, 1%, 33%, 0.2);
$border-colour-light: hsla(0, 0%, 78%, 0.2);
$fieldSet-bg-colour: rgba(245, 245, 245, 1);
$fieldSet-bg-colour-film: rgba(0, 135, 255, 1);
$header-height: 48px;
$footer-height: 62px;
$image-shadow-colour: rgba(12, 12, 12, 0.4);
$notice-message-size: 1.2em;
$dark-blue: darkblue;

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

/* Set core body defaults */
html,
body {
  height: 100%;
}
body {
  font-family: 'Signika', sans-serif;
  font-family: 'Comfortaa', cursive;
  background-color: $main-bg-colour;
  color: $main-font-colour;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-size: 'min(18px, 4.2vw)';
  margin: 0;
  padding: 0.2em;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}
a:link {
  text-decoration: none;
}
a {
  color: $main-bg-colour;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
  // text-transform: uppercase;
}

button:hover {
  cursor: pointer;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding-bottom: 2px;
  font-family: 'Bebas Neue', cursive;
  letter-spacing: 0.4em;
}
/* Responsive layout */
@media (max-width: 812px) {
  button {
    font-size: 0.9em;
    text-transform: uppercase;
  }
}
/* General global stye settings */
.dates-wrapper {
  margin-top: 0.8em;
  font-size: 0.7em;
  display: flex;
  justify-content: space-between;
}

fieldset.fieldSet {
  margin-top: 0.2em;
  border: 1px solid $border-colour-light;
  border-bottom: 2px solid $border-colour-dark;
  background-color: $dark-blue;
  border-radius: 4px;
}
fieldset legend {
  position: relative;
  top: 2px;
  text-transform: uppercase;
  font-size: 1.4em;
  font-family: 'Bebas Neue', cursive;
  letter-spacing: 0.3em;
  color: $main-bg-colour;
}
fieldset form {
  border: 1px solid $border-colour-dark;
}
fieldset button {
  margin-top: 1em;
  width: auto;
}

textarea {
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  margin: 0;
  outline: 0;
  padding: 4px;
  width: 100%;
  min-height: 150px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.6);
  color: $main-font-colour;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
}
textarea.invalid {
  border-left: $secondary-bg-colour 4px solid;
  min-height: 50px;
}
textarea.entered {
  border-left: greenyellow 4px solid;
  min-height: 50px;
}
textarea:focus {
  background-color: rgba(255, 255, 255, 1);
  color: $main-font-colour;
}

.small-text {
  font-size: 0.76em;
  font-weight: bold;
}
.small-text.warning {
  color: $rating-colour;
  text-transform: uppercase;
}
.small-text.danger {
  color: $danger;
  font-size: 1rem;
  text-transform: uppercase;
}

hr.style-one {
  margin: 0.5em 0;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}
@import './css/scrollBar.scss';
.ra-thumbs-down {
  margin: 0.2em;
  color: $danger;
}
.ra-thumbs-up {
  margin: 0.2em;
  color: $success;
}
.ra-thumbs-up {
  margin: 0.2em;
  color: $success;
}
.fa-user-login {
  margin: 0.2em;
  color: $success;
}
.fa-user-logout {
  margin: 0.2em;
  color: $danger;
}

.border-temp {
  border: 20px solid green;
}
