@import './index.scss';

$grid__bp-md: 1280;

.container {
  max-width: $grid__bp-md * 1px;
  margin: 0 auto;
  &--fluid {
    margin: 0;
    max-width: 100%;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
}

.content-wrapper {
  height: calc(100vh - #{$header-height + $footer-height});
  overflow-y: auto;
  background-color: $main-font-colour;
  color: $main-bg-colour;
  padding: 0.2em;
  border-radius: 4px;
}

@media (max-width: 390px) {
  .wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .admin-nav-buttons-wrapper {
    flex-wrap: wrap;
  }
}

@media (min-width: 845px) {
  .content-wrapper {
    margin: 0 auto;
    max-width: 100%;
  }
}
